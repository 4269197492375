<!-- 培训学员考勤新增 -->
<template>
    <div class="AddStudentClockIn">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">培训学员考勤新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="form" inline label-width="150px">
            <el-form-item label="签到单号" >
                <el-input v-model="form.number" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="助记编号" >
                <el-input v-model="form.mnemonic" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="制单日期" >
                <el-date-picker
                value-format="yyyy-MM-dd"
                      v-model="form.makeDate"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="培训班级" prop="trainClass">
                <el-select v-model="form.trainClass" filterable placeholder="请输入并选择培训班级"
                 :clearable="true" @change="trainClassChange">
                    <el-option
                      v-for="item in form.trainClassOpt"
                      :key="item.id"
                      :label="item.number"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="班级名称" prop="className">
                <el-input v-model="form.className" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="本班学员数" >
                <el-input v-model="form.studentNum" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="学科" prop="subject">
                <el-input v-model="form.subject" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="授课教师" prop="teacher">
                <el-select v-model="form.teacher" filterable placeholder="请输入并选择授课教师"
                 :clearable="true" >
                    <el-option
                      v-for="item in form.teacherOpt"
                      :key="item.id"
                      :label="item.fullname"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="选择课表" >
                <el-select v-model="form.Timetable" filterable placeholder="请输入并选择课表"
                 :clearable="true" @change="TimetableChange">
                    <el-option
                      v-for="item in form.TimetableOpt"
                      :key="item.id"
                      :label="item.class_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="上课日期" >
                <el-date-picker
                value-format="yyyy-MM-dd"
                      v-model="form.upperDate"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="上课时间" >
                 <el-time-picker
                 value-format="HH:mm"
                    format="HH:mm"
                    v-model="form.upperTime"
                    :picker-options="{
                      selectableRange: '00:00:00 - 23:59:59'
                    }"
                    placeholder="选择时间">
                  </el-time-picker>
            </el-form-item>
            <el-form-item label="老师类型" >
                <el-select v-model="form.teacherType" placeholder="请选择类型" :clearable="true">
                    <el-option
                      v-for="item in form.TypeOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="试学人数" >
                <el-input v-model="form.learnNum" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="补课人数" >
                <el-input v-model="form.repairNum" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="签到人数" >
                <el-input v-model="form.signNum" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="授课教室" prop="classRoom">
                <el-select v-model="form.classRoom" filterable  placeholder="请输入并选择授课教室"
                 :clearable="true" >
                    <el-option
                      v-for="item in form.classRoomOpt"
                      :key="item.id"
                      :label="item.site_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="录入人员" prop="personnel">
                <el-select v-model="form.personnel" filterable placeholder="请输入并选择录入人员"
                 :clearable="true" >
                    <el-option
                      v-for="item in form.personnelOpt"
                      :key="item.id"
                      :label="item.fullname"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="说明">
                <el-input v-model="form.remark" type="textarea" class="text-ipt" placeholder="请输入"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    number:"",//签到单号
                    mnemonic:"",//助记编号
                    makeDate:"",//制单日期
                    trainClass:"",//培训班级
                    trainClassOpt:[],//培训班级选项
                    teacher:"",//授课教师
                    teacherOpt:[],//授课老师选项
                    subject:"",//学科
                    className:"",//班级名称
                    Timetable:"",//选择课表
                    TimetableOpt:[],//课表选项
                    teacherType:1,//老师类型
                    TypeOpt:[
                        {label: "授课", value: 1},
                        {label: "代课", value: 2}
                    ],//老师类型选项
                    upperDate:"",//上课日期
                    upperTime:"",//上课时间
                    studentNum:"",//本班学员数
                    learnNum:"",//试学人数
                    repairNum:"",//补课人数
                    signNum:"",//签到人数
                    classRoom:"",//授课教室
                    classRoomOpt:[],//授课教室选项
                    personnel:"",//录入人员
                    personnelOpt:[],//录入人员选项
                    remark:""//说明
                },
                rules:{
                    trainClass:[
                         { required: true, message: '培训班级不能为空', trigger: 'change' },
                    ],
                    teacher:[
                         { required: true, message: '授课教师不能为空', trigger: 'change' },
                    ],
                    subject:[
                        { required: true, message: '学科不能为空', trigger: 'blur' },
                    ],
                    className:[
                        { required: true, message: '班级名称不能为空', trigger: 'blur' },
                    ],
                    classRoom:[
                        { required: true, message: '授课教室不能为空', trigger: 'change' },
                    ],
                    personnel:[
                        { required: true, message: '录入人员不能为空', trigger: 'change' },
                    ]
                },
                id:"",
                bool:false
            }
        },
        created() {
            // 授课教室
            this.$request({
                url:'/api/schoolarea/list',
                method:'POST',
                data:{
                    page:1,
                    limit:500
                }
            }).then(res=>{
                if(res.code==1){
                    // console.log(res,'授课教室')
                    this.form.classRoomOpt=res.data.list
                }
            })
            
            
            // 培训班级数据
            this.$request({
                url:'/api/class_/list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                if(res.code==1){
                    // console.log(res,'培训班级数据')
                    this.form.trainClassOpt=res.data.list
                }
            })
            
            // 授课老师数据 录入人员
            this.$request({
                url:'/api/humanresources/list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                if(res.code==1){
                    // console.log(res,'授课老师数据 录入人员')
                    this.form.teacherOpt=res.data.list
                    this.form.personnelOpt=res.data.list
                    
                }
            })

        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            //培训班级改变事件
            trainClassChange(e){
                // console.log(e)
                let result = this.form.trainClassOpt.find(item => item.id == e)
                this.form.className=result.class_name//班级名称
                this.form.studentNum=result.actual_size//本班学员数
                this.form.subject=result.course//学科
                
                // 排课表数据
                if(result.id){
                    this.$request({
                        url:'/api/class_/scheduleList',
                        method:'POST',
                        data:{
                            class_id:result.id,
                            page:1,
                            limit:100
                        }
                    }).then(res=>{
                        if(res.code==1){
                            console.log(res,'排课表数据')
                            this.form.TimetableOpt=res.data.list
                        }
                    })
                }else{
                    this.$message({
                        message: '请先选择培训班级',
                        type: 'error'
                    })
                }
            },
            TimetableChange(e){//排课表改变事件
                let result = this.form.TimetableOpt.find(item => item.id == e)
                this.form.upperDate=result.course_date//上课日期
                this.form.upperTime=result.course_time//上课时间
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        let form = this.form
                        this.$request({
                            url:'/api/classsign/add',
                            method:'POST',
                            data:{
                               number:form.number,//签到单号
                               code:form.mnemonic, //助记编号
                               document_time:form.makeDate,//制单日期
                               class_id:form.trainClass,//班级ID
                               class_name:form.className,//班级名称
                               resources_id:form.teacher,//授课老师ID
                               schedule_id:form.Timetable,//排课表ID
                               classroom_id:form.classRoom,//上课教室id
                               course:form.subject,//学科
                               resources_type:form.teacherType,//授课老师类型
                               class_size:form.studentNum,//人数
                               course_date:form.upperDate,//上课日期
                               course_time:form.upperTime,//上课时间
                               add_id:form.personnel,//录入人id
                               remark:form.remark//说明
                            }
                        }).then(res=>{
                            if(res.code==1){
                                this.$message({
                                    message: '新增列表成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }else{
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.goBack()
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .AddStudentClockIn{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    .form ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #fff;
        color:#606266;
    }
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
